import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/smartnuance/blog/src/components/Layout/index.tsx";
import ContactInfo from 'components/ContactInfo';
import { PaperPlaneTilt, Phone, MapPin } from "phosphor-react";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const Container = makeShortcode("Container");
const TitleSection = makeShortcode("TitleSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact" mdxType="SEO" />
    <Container section mdxType="Container">
      <TitleSection title="Contact details" subtitle="I'm curious to listen to your thoughts" center mdxType="TitleSection" />
    </Container>
    <Container mdxType="Container">
      <ContactInfo title="Phone" icon={<Phone mdxType="Phone" />} mdxType="ContactInfo">
+351 91 298 316 3
      </ContactInfo>
      <ContactInfo title="Address" icon={<MapPin mdxType="MapPin" />} mdxType="ContactInfo">
        <p>{`Rua do Meio à Lapa  23, 3° Esq,`}</p>
        <p>{`1200-721 Lisboa`}</p>
        <p>{`Portugal`}</p>
      </ContactInfo>
      <ContactInfo title="Email" icon={<PaperPlaneTilt mdxType="PaperPlaneTilt" />} mdxType="ContactInfo">
        <p><a parentName="p" {...{
            "href": "mailto:simon@smartnuance.com"
          }}>{`simon@smartnuance.com`}</a></p>
      </ContactInfo>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      